import Card from "./card.model";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";

export default class Payment {
    constructor() {
        this.amount = 0.00;
        this.paymentMethods = [];
        this.senderHash = "";
        this.card = new Card();
        this.installments = [];
        this.paymentMethod = PaymentMethod.creditCard;
    }
}