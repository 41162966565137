
export default class Document {

    constructor(type) {
        /**
         * Tipo de documento do comprador. Especifica o tipo do documento é CPF ou CNPJ. 
         * Formato:CPF ou CNPJ
         */
        this.type = type;
    
        /**
         * Número do CPF ou CNPJ do comprador Especifica o CPF/CNPJ do comprador que está realizando o pagamento. Este campo é opcional e você pode enviá-lo caso já tenha capturado os dados do comprador em seu sistema e queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: Um número de 11 dígitos para CPF ou 14 dígitos para CNPJ. 
         */
        this.value = 0;   
    }
}