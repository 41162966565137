import Holder from "./holder.model";
import Address from './address.model';
import Installment from "./installment.model";

export default class Card {
    constructor() {
        this.cardNumber = '';
        this.brand = null;
        this.cvv = '';
        this.expirationMonth = '';
        this.expirationYear = '';
        this.token = '';
        this.holder = new Holder();
        this.billingAddress = new Address();
        this.installment = new Installment();
    }

    get cardBin() {
        return this.cardNumber.slice(0, 6);
    }
}