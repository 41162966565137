<template>
  <div>
    {{ session }}
    <br />
    SenderHash: {{ senderHash }}
    <br />
    BrandName: {{ brandName }}
    <br />
    CardToken: {{ cardToken }}
    <br />
    <br />
    <br />
    <button @click="createCheckout">Cartão de Crédito</button>
    <br />
    <br />
    <br />
    <br />
    <button @click="createPreApproval">Assinatura</button>
  </div>
</template>

<script type="plain/text">
import PagSeguroService from "@/scripts/services/pagSeguro.service";
import Checkout from "@/scripts/models/pagSeguro/checkout.model";
import DocumentHolder from "@/scripts/models/pagSeguro/documentHolder.model";
import Payment from "@/scripts/models/pagSeguro/payment.model";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";

export default {
  data() {
    return {
      payment: new Payment(),
      pagSeguroService: new PagSeguroService(),
      session: {},
      senderHash: "",
      brandName: "",
      cardToken: ""
    };
  },
  mounted() {
    this.init();

    // this.pagSeguroService.createSession().then(this.onSessionCreated);
  },
  methods: {
    init() {
      this.payment.amount = "10.00";
      this.paymentMethod = PaymentMethod.creditCard;
      this.payment.card.cardNumber = "4111111111111111";
      this.payment.card.expirationMonth = "12";
      this.payment.card.expirationYear = "2030";
      this.payment.card.cvv = "123";
      this.payment.card.installment.value = "20.00";
      this.payment.card.installment.noInterestInstallmentQuantity = null;

      this.payment.card.holder.name = "José Comprador";
      this.payment.card.holder.documents.push(new DocumentHolder());
      this.payment.card.holder.documents[0].document.type = "CPF";
      this.payment.card.holder.documents[0].document.value = "25241790012";

      this.payment.card.holder.birthDate = "27/07/1994";
      this.payment.card.holder.phone.areaCode = "15";
      this.payment.card.holder.phone.number = "999999999";

      this.payment.card.billingAddress.street = "João Martini Netto";
      this.payment.card.billingAddress.number = "108";
      this.payment.card.billingAddress.complement = "";
      this.payment.card.billingAddress.district = "Deolinda Guerra";
      this.payment.card.billingAddress.city = "Sorocaba";
      this.payment.card.billingAddress.state = "SP";
      this.payment.card.billingAddress.country = "BRA";
      this.payment.card.billingAddress.postalCode = "18052723";
    },
    createPreApproval() {
      this.pagSeguroService
        .createSubscription(this.senderHash, this.cardToken)
        .then(this.onPreApprovalCreated);
    },
    createCheckout() {
      // this.pagSeguroService
      //   .createCheckout(new Checkout())
      //   .then(this.onCheckoutCreated);
      this.pagSeguroService.createTransaction(this.senderHash, this.cardToken);
    },
    onPreApprovalCreated(preApproval) {
      console.log(preApproval);
      let url =
        "https://sandbox.pagseguro.uol.com.br/v2/pre-approvals/request.html?code=" +
        preApproval.code;

      console.log(url);
    },
    onSessionCreated(session) {
      console.log("sessão ok");
      PagSeguroDirectPayment.setSessionId(session.id);
      this.session = session;
      this.prepareSenderHash();

      // PagSeguroDirectPayment.getPaymentMethods({
      //   amount: this.payment.amount,
      //   success: this.onPaymentMethodsLoaded,
      //   error: this.onError
      // });
    },
    prepareSenderHash() {
      PagSeguroDirectPayment.onSenderHashReady(this.onSenderHashReady);
    },
    onPaymentMethodsLoaded(response) {
      console.log("métodos de pagamento ok");
      this.payment.paymentMethods = response.paymentMethods;
      PagSeguroDirectPayment.onSenderHashReady(this.onSenderHashReady);
    },
    onSenderHashReady(response) {
      console.log("sender hash");
      console.log(response.senderHash);
      this.senderHash = response.senderHash;
      if (response.status == "error") {
        alert(response.message);
        return false;
      }

      this.payment.senderHash = response.senderHash;

      PagSeguroDirectPayment.getBrand({
        cardBin: this.payment.card.cardBin,
        success: this.onBrandLoaded,
        error: this.onError
      });
    },
    onBrandLoaded(response) {
      console.log("marca carregada");
      this.brandName = response.brand.name;
      this.payment.card.brand = response.brand.name;

      PagSeguroDirectPayment.createCardToken(
        Object.assign(this.payment.card, {
          success: this.onCardTokenCreated,
          error: this.onError
        })
      );
    },
    onCardTokenCreated(response) {
      this.cardToken = response.card.token;
      this.payment.card.token = response.card.token;
      

      // this.pagSeguroService.createTransaction(this.payment.card);
      // this.pagSeguroService
      //   .createPreApproval(this.payment.card)
      //   .then(this.onPreApprovalCreated);
    },
    onCheckoutCreated(checkout) {
      //Insira o código de checkout gerado no Passo 1
      var code = checkout.code;
      var callback = {
        success: function(transactionCode) {
          //Insira os comandos para quando o usuário finalizar o pagamento.
          //O código da transação estará na variável "transactionCode"
          console.log(
            "Compra feita com sucesso, código de transação: " + transactionCode
          );
        },
        abort: function() {
          //Insira os comandos para quando o usuário abandonar a tela de pagamento.
          console.log("abortado");
        }
      };

      let url =
        "https://sandbox.pagseguro.uol.com.br/v2/checkout/payment.html?code=" +
        code;

      console.log(url);
    },
    onError(response) {
      alert("Houve um erro, veja a abaixo");
      console.log(response);
    }
  }
};
</script>
