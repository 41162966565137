export default class Address {
    constructor() {
        /**
         * Nome da rua do endereço de envio. Informa o nome da rua do endereço de envio do produto. Pode enviá-lo caso queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: Livre, com limite de 80 caracteres.
         */
        this.street = '';

        /**
         * Número do endereço de envio. Informa o número do endereço de envio do produto. Pode enviá-lo caso queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: Livre, com limite de 20 caracteres.
         */
        this.number = 0;

        /**
         * Complemento do endereço de envio. Informa o complemento (bloco, apartamento, etc.) do endereço de envio do produto. Pode enviá-lo caso queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: Livre, com limite de 40 caracteres.
         */
        this.complement = '';

        /**
         * Bairro do endereço de envio. Informa o bairro do endereço de envio do produto. Este campo é opcional e você pode enviá-lo caso já tenha capturado os dados do comprador em seu sistema e queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: Livre, com limite de 60 caracteres.
         */
        this.district = '';

        /**
         * Cidade do endereço de envio. Informa a cidade do endereço de envio do produto. Pode enviá-lo caso queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: Livre. Deve ser um nome válido de cidade do Brasil, com no mínimo 2 e no máximo 60 caracteres.
         */
        this.city = '';

        /**
         * Estado do endereço de envio. Informa o estado do endereço de envio do produto. Pode enviá-lo caso queira evitar que ele preencha esses dados novamente no PagSeguro.
         * Formato: Duas letras, representando a sigla do estado brasileiro correspondente.
         */
        this.state = '';

        /**
         * País do endereço de envio. Informa o país do endereço de envio do produto. Pode enviá-lo caso queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: No momento, apenas o valor BRA é permitido.
         */
        this.country = '';

        /**
         * CEP do endereço de envio. Informa o CEP do endereço de envio do produto. Pode enviá-lo caso queira evitar que ele preencha esses dados novamente no PagSeguro. 
         * Formato: Um número de 8 dígitos
         */
        this.postalCode = 0;
    }
}